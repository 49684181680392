<template>
  <div>
    <c-search-box @enter="getTables">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-select
            :comboItems="tableItems"
            type="edit"
            itemText="tableName"
            itemValue="tableId"
            name="table"
            label="대상테이블"
            v-model="searchParam.table"
            required
          ></c-select>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-select
            :comboItems="gtypeItems"
            type="edit"
            itemText="codeName"
            itemValue="code"
            name="gtype"
            label="Generate Type"
            v-model="searchParam.gtype"
            required
          ></c-select>
        </div>
      </template>
    </c-search-box>
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
        <c-card title="Generate Result" class="cardClassDetailForm">
          <template slot="card-button">
            <q-btn-group outline >
              <c-btn label="검색" icon="search" @btnClicked="GeneratorTable" />
            </q-btn-group>
          </template>
          <template slot="card-detail">
            <div class="col-12" v-html="this.resultGenerate" id="resultGen">
            </div>
          </template>
        </c-card>
      </div>
    </div>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';

export default {
  name: 'db-generator',
  data() {
    return {
      dbtableListUrl: '',
      dbtableInfoUrl: '',
      resultGenerate: '',
      searchParam: {
        table: null,
        gtype: null,
      },
      tableItems: [],
      gtypeItems: [
        { code: 'class', codeName: 'model class' },
        { code: 'select', codeName: 'select query' },
        { code: 'insert', codeName: 'insert query' },
        { code: 'update', codeName: 'update query' },
        { code: 'jsongridhead', codeName: 'json-그리드헤더' },
        { code: 'jsondata', codeName: 'json-Data결과수신' },
        { code: 'jsondatacomment', codeName: 'json-Data결과수신(주석포함)' },
      ]
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.dbtableListUrl = selectConfig.devtool.dbtables.url;
      this.dbtableInfoUrl = selectConfig.devtool.dbtable.url;
      this.getTables();
    },
    getTables() {
      this.$http.url = this.$format(
        this.dbtableListUrl
      );
      this.$http.type = 'GET';
      this.$http.request((_result) => {
        this.tableItems = this.$_.clone(_result.data);
      });
    },
    GeneratorTable() {
      this.$http.url = this.$format(
        this.dbtableInfoUrl,
        this.searchParam.table,
        this.searchParam.gtype
      );
      this.$http.type = 'GET';
      this.$http.request((_result) => {
        this.resultGenerate = _result.data.resultStr;
      });
    }
  }
};
</script>
